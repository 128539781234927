import React, { useEffect, useState } from 'react'
import { getSettingsData } from '../Hooks/UseBackend';
import { BsFacebook } from 'react-icons/bs';
import { FaTwitterSquare, FaInstagram } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';
import Images from './Simage';
import { Link } from 'react-router-dom';
import { getCmslists, getsociallists } from '../Actions/cmsFrontAxios';
import { IMG_URL } from '../Config/env'
const Footer = () => {
    const [settingdata, setsettingdata] = useState([])
    const [AllCmsdata, setAllCmsdata] = useState([])
    const [SocialData, setSocialData] = useState([])
    useEffect(() => {
        getData()
        GetCmslists()
        GetSociallink()
    }, [])

    const getData = async () => {
        var settingdata = await getSettingsData();
        setsettingdata(settingdata);


    }


    const GetCmslists = async () => {
        try {
            let resp = await getCmslists();
            console.log("getCmslists", resp.data);
            setAllCmsdata(resp.data)
        } catch (error) {
            console.log("error", error);
        }
    }

    const CmsData = {

        Footercontent: AllCmsdata.find((val) => val.identifier == "footercontent")

    }
    console.log("cmsDatavalues", CmsData);


    const GetSociallink = async () => {
        try {
            let resp = await getsociallists()
            console.log("responsse", resp.data);
            if (resp?.status) {
                setSocialData(resp?.data)

            }
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <footer className='footer'>
            {/* <span className='greengradientfooter'></span> */}
            <Container>
                <Row className='justify-content-between'>
                    <Col xs={12} sm={12} md={8} lg={6} className='mb-4 mb-md-0'>
                        <img src={Images.flogo} alt='Footer logo' className='footlogo' />
                        <p className='content my-4'>
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                          aliquip ex ea commodo consequat. */}
                            {CmsData?.Footercontent?.Content}
                        </p>
                        <ul className='socialicons'>
                            {/* <li>
                                <Link>
                                    <img src={Images.telegram} alt='telegram' />
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <img src={Images.twitter} alt='twitter' />
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <img src={Images.instagram} alt='instagram' />
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <img src={Images.discord} alt='discord' />
                                </Link>
                            </li> */}

                            {/* {SocialData.map((mlink) => (

                                    <>
                                        {
                                            <li>
                                                <a href={mlink.link} target="blank">

                                                    <img src={`${IMG_URL}/sociallink/${mlink?.image}`} className="img-fluid simg" />
                                                </a>
                                            </li>
                                        }
                                    </>
                                ))} */}
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} md={3} lg={3}>
                        <p className='foothead'>Quick Access</p>
                        <ul className='footlinks mt-3'>
                            {/* <li>
                                <Link to="/launchpad">Launchpad</Link>
                            </li>
                            <li>
                                <Link to="/staking">Locked Staking</Link>
                            </li>*/}

                            <li>
                                <Link to="/whitepaper">White Paper</Link>
                            </li>
                            <li>
                                <Link to="/news">News</Link>
                            </li>
                        </ul>
                    </Col>
                </Row>
                {/* <div className='row'>                    
                    <div className='col-sm-4'>
                        <ul className='text-sm-start ps-0'>
                           <li>
                                <a target="blank" href={settingdata && settingdata[0]?.Description}>
                                    <span className='fa fa-facebook-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[1]?.Description}>
                                    <span className='fa fa-twitter-square'></span>
                                </a>
                            </li>
                            <li>
                                <a target="blank"href={settingdata && settingdata[2]?.Description}>
                                    <span className='fa fa-instagram'></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='col-sm-8 col-12'>
                        <p className='textclr'>COPY RIGHT @ 2023 .ALL RIGHTS RESERVED</p>
                    </div>
                    <div className='col-sm-4 col-12 text-end'>
                       <a href='/'><BsFacebook/></a>
                       <a href='/'><FaTwitterSquare/></a>
                       <a href='/'><FaInstagram/></a>
                    </div>
                </div> */}

            </Container>
        </footer>
    )
}

export default Footer